import { t, locale } from 'i18n'
import 'vanilla-cookieconsent'

const availableLocales = {
  lv: 'latviešu',
  en: 'english',
}

const otherLocaleLinks = () => {
  let links = []

  for (const [localeKey, localeName] of Object.entries(availableLocales)) {
    if (localeKey === locale) continue // Do not show link to current locale
    links.push(`<a href="/locales/change?locale=${localeKey}">${localeName.toLowerCase()}</a>`)
  }

  return links
}

const privacyPolicyPageSlug = {
  lv: 'privatuma-politika',
  en: 'privacy-policy',
}[locale]

const config = {
  current_lang: locale,
  autoclear_cookies: true,
  page_scripts: true,
  force_consent: true,
  mode: 'opt-in',
  cookie_name: 'LatvijasNotarsConsent',
  cookie_expiration: 365,
  cookie_necessary_only_expiration: 365,
  hide_from_bots: true,
  delay: 0,

  gui_options: {
    consent_modal: {
      layout: 'bar',
      position: 'top center',
      transition: 'slide',
      swap_buttons: false,
    },
    settings_modal: {
      layout: 'box',
      transition: 'slide',
    },
  },

  languages: {
    [locale]: {
      consent_modal: {
        title: `
          ${t('cookie_consent.cookies')}
          <div class="locale-switcher">${otherLocaleLinks().join(' | ')}</div>
        `,
        description: `
          ${t('cookie_consent.description')}<br>
          <a href="/pages/${privacyPolicyPageSlug}" target="_blank">
            ${t('cookie_consent.privacy_policy')}
          </a>
        `,
        primary_btn: {
          text: t('cookie_consent.accept'),
          role: 'accept_all',
        },
        secondary_btn: {
          text: t('cookie_consent.view_details'),
          role: 'settings',
        },
      },
      settings_modal: {
        title: t('cookie_consent.cookies'),
        save_settings_btn: null,
        accept_all_btn: t('cookie_consent.accept'),
        close_btn_label: t('cookie_consent.close'),
        cookie_table_headers: [
          { col1: t('cookie_consent.table_headers.name') },
          { col2: t('cookie_consent.table_headers.description') },
          { col3: t('cookie_consent.table_headers.expiration') },
        ],
        blocks: [
          {
            title: t('cookie_consent.cookie_usage'),
            description: `
              ${t('cookie_consent.description')}<br>
              <a href="/pages/${privacyPolicyPageSlug}" target="_blank">
                ${t('cookie_consent.privacy_policy')}
              </a>
            `,
          },
          {
            title: t('cookie_consent.mandatory_cookies'),
            cookie_table: [
              {
                col1: '_LNA',
                col2: t('cookie_consent.descriptions.lna'),
                col3: t('cookie_consent.duration_hours', { count: 8 }),
              },
              {
                col1: 'LatvijasNotarsConsent',
                col2: t('cookie_consent.descriptions.latvijas_notars_consent'),
                col3: t('cookie_consent.duration_years', { count: 1 }),
              },
            ],
          },
        ],
      },
    },
  },
}

document.addEventListener('DOMContentLoaded', () => {
  const cookieConsent = window.initCookieConsent()
  cookieConsent.run(config)
})
